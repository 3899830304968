@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* V - STEPS */
.step {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: cream;
}

.v-stepper {
  position: relative;
  /*   visibility: visible; */
}

/* regular step */
.step .circle {
  background-color: gray;
  border: 3px solid gray;
  border-radius: 100%;
  width: 20px; /* +6 for border */
  height: 20px;
  display: inline-block;
  margin-left: 3px;
}

.step.failed .circle {
  border: 0px !important;
  background-image: url("https://storage.googleapis.com/trv-dev-app-carga-media/failed-detail-icon.png") !important;
  background-repeat: no-repeat;
  background-color: #fff !important;
  background-position: center;
}

.step .line {
  top: 23px;
  left: 12px;
  height: 100%;
  position: absolute;
  border-left: 3px solid #fff;
}

.step.completed .circle {
  visibility: visible;
  background-color: #fe6b00;
  border-color: #fe6b00;
}
.step.completed .karri {
  visibility: visible;
  background-color: #1c9996;
  border-color: #1c9996;
}

.step.completed .line {
  border-left: 3px solid #fff;
}

.step.active .circle .-karri {
  border-color: #1c9996 !important;
}

.step.active .circle {
  visibility: visible;
  border-color: #fe6b00;
}

.step.empty .circle {
  visibility: hidden;
}

.step.empty .line {
  /*     visibility: hidden; */
  /*   height: 150%; */
  top: 0;
  height: 150%;
}

.step:last-child .line {
  border-left: 3px solid white;
  z-index: -1; /* behind the circle to completely hide */
}

.content {
  margin-left: 20px;
  display: inline-block;
}

/* H - STEPS */
.step-name {
  color: #c0c0c0;
  font-weight: bold;
}

.completed .step-name {
  color: #000;
  font-weight: bold;
}
.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin: 0 200px 0 0;
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 3px solid #fff;
  border-style: dashed;
  width: 280%;
  top: 40px;
  left: 78%;
  z-index: 2;
}
.stepper-item:last-child::before {
  position: absolute;
  content: "";
  width: 0%;
  top: 40px;
  left: 78%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 3px solid #fff;
  border-style: dashed;
  width: 220%;
  top: 40px;
  left: 78%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}

div.completed.received > div.step-counter {
  background-image: url("https://storage.googleapis.com/trv-dev-app-carga-media/received-adctive-icon.png");
  width: 78px;
  height: 78px;
}
div.on-route > div.step-counter {
  background-image: url("https://storage.googleapis.com/trv-dev-app-carga-media/on-route-icon.png");
  width: 78px;
  height: 78px;
}

div.completed.on-route > div.step-counter {
  background-image: url("https://storage.googleapis.com/trv-dev-app-carga-media/on-route-active.icon.png");
  width: 78px;
  height: 78px;
}

div.delivered > div.step-counter {
  background-image: url("https://storage.googleapis.com/trv-dev-app-carga-media/delivered-icon.png");
  width: 78px;
  height: 78px;
}

div.completed.delivered > div.step-counter {
  background-image: url("https://storage.googleapis.com/trv-dev-app-carga-media/delivered-active-icon.png");
  width: 78px;
  height: 78px;
}

.text-failed {
  color: red;
  font-weight: bold;
}
